<template>
  <div v-if="isPermission">
    <CCard class="shadow-sm m-2">
      <CCardBody>
        <CRow>
          <CCol sm="1" col="2">
            <img src="/img/icon/store.png" style="width: 32px; height:32px;"/>
          </CCol>
          <CCol sm="9" col="7">
            <select class="custom-select text-dark" v-model="shop" disabled>
              <option v-for="shop in shops" :key="shop.objectId" :value="shop">
                {{ shop.shopName }} - {{ shop.branchName }}
              </option>
            </select>

            <div v-if="validateShop" class="text-danger">
              {{ validateShop }}
            </div>
          </CCol>
          <div class="col-md-2 col-sm-2 col-3 text-right">
            <CButton block color="light" @click="goback()">
              <span><i class="fi fi-rr-arrow-left mr-2" style="position: relative; top: 2px;"></i></span>{{ $t('back') }}
            </CButton>
          </div>
        </CRow>
      </CCardBody>
    </CCard>
    <CCol md="12" lg="12" class="mt-4">
      <CCard class="shadow-sm">
        <CCardBody>
          <CAlert color="success" v-if="isSuccess === true">
            {{ $t('createProductSuccess') }}
          </CAlert>
          <div class="row">
            <div class="col-md-12 col-sm-12">
              <h2 class="font-weight-normal">{{ $t('productSN') }}</h2>
              <div class="row">
                <div class="col-md-10 col-sm-9 col-9">
                  <h2 class="font-weight-normal">
                    <span class="h3">
                      {{ $t('productName') }} :
                      {{ SKUName }}
                    </span>
                  </h2>
                </div>
                <div class="col-md-2 col-sm-3 col-3 text-right h5">
                  <span class="font-weight-normal text-right h5">
                    {{ $t('quantity') }}
                  </span>
                  ({{ countSeriallist }})
                </div>
              </div>
            </div>
          </div>
          <hr />
          <CRow>
            <CCol sm="12" lg="4" md="4" col="12">
              <CCard class="cardadd" style="min-height: 167px">
                <CCardBody>
                  <h5 class="font-weight-normal">{{ $t('addSerial') }}</h5>
                  <CRow>
                    <CCol md="9" sm="10" col="12">
                      <CInput
                        v-model.trim="SerialNumber"
                        placeholder="ใส่หมายเลข Serial"
                      />
                      <CAlert color="danger" :show.sync="isError">
                        {{ titleError }}
                      </CAlert>
                    </CCol>
                    <CCol md="3" sm="2" col="6">
                      <CButton
                        block
                        color="success"
                        @click="confirmAdd"
                        v-if="isEditData"
                      >
                        {{ $t('add') }}
                      </CButton>
                      <CButton block color="success" disabled v-else>
                        {{ $t('add') }}
                      </CButton>
                    </CCol>
                    <CCol md="12" sm="12" col="6">
                      <CButton
                        block
                        color="success"
                        @click="generate"
                        v-if="isEditData"
                      >
                        {{ $t('createSerial') }}
                      </CButton>
                      <CButton block color="success" disabled v-else>
                        {{ $t('createSerial') }}
                      </CButton>
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol sm="12" lg="8" md="8">
              <CCard class="cardadd">
                <CCardBody>
                  <table
                    class="table table-hover"
                    style="width: 100%;white-space:nowrap;display: block;height: 400px;overflow-y: scroll;"
                  >
                    <thead>
                      <tr>
                        <th class="text-dark font-weight-normal">{{ $t('productSN') }}</th>
                        <th class="text-dark font-weight-normal">{{ $t('status') }}</th>
                        <th v-if="isEditData"></th>
                      </tr>
                    </thead>
                    <tbody v-if="loadingButton == true">
                      <tr
                        style="min-height: 300px; max-height: 300px ;width: 100%; white-space:nowrap"
                        v-for="(item, index) in this.SerialList"
                        :key="index"
                        hover
                        border
                        striped
                        
                      >
                        <td style="width: 85%" class="text-dark">{{ item.serialNumber }}</td>
                        <td
                          v-if="(item.serialStatus = 'P1')"
                          style="width: 100%"
                          class="text-dark"
                        >
                          {{ $t('available') }}
                        </td>
                        <td v-else style="width: 100%" color="red">
                          {{ $t('soldOut') }}
                        </td>
                        <td style="width: 4%" v-if="isEditData">
                          <img src="/img/trash.png" style="width: 14px; height: 14px; cursor: pointer; "
                          @click="confirmRemove(item, index)" disabled>
                        </td>
                      </tr>
                    </tbody>
                    <div v-else class="text-center" width="100%">
                      <button
                        class="btn "
                        type="button"
                        disabled
                        style="color: darkcyan;"
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        >
                        </span>
                        {{ $t('loading') }}
                      </button>
                    </div>
                  </table>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
    <modal
      color="success"
      :show.sync="confirmModal"
      :title="$t('confirmSaveImg')"
      v-on:isSave="addSerial"
      centered
      :alertError="alertError"
      :loading="loading"
    >
      <div v-if="alertError">
        <h4 class="text-center text-danger">{{ textError }}</h4>
        <h5 class="mt-4 text-center text-danger">{{ textMessage }}</h5>
      </div>
      <div v-else>
        <h4 class="mt-4 mb-4 text-center">{{ $t('confirmSaveSerial') }}</h4>
      </div>
    </modal>
    <CRow>
      <modal
        color="danger"
        :show.sync="deleteModal"
        :title="$t('confirmDeleteProduct')"
        v-on:isSave="removeSerial"
        :loading="loading"
        centered
      >
        <h4 class="mt-4 mb-4 text-center">{{ $t('confirmDelete') }}</h4>
      </modal>
    </CRow>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pos from '@/services/local'
import util from '@/util/util'
import permis from '@/util/permission'

export default {
  data() {
    return {
      isSuccess: false,
      SKUName: '',
      keyword: '',
      data: [],
      dataSKUbytype: [],
      itemObjectId: [],
      validateShop: false,
      shop: this.$store.getters.shopObjectId,
      addlist: [],
      Serial: [],
      SerialList: [],
      countSeriallist: 0,
      quantity: 1,
      countAddList: 0,
      SerialNumber: '',
      loadingButton: true,
      confirmModal: false,
      loading: true,
      title: '',
      deleteModal: false,
      itemDelete: {},
      isError: 0,
      titleError: '',
      alertError: false,
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date']),
    isPermission() {
      let path = '/product'
      return permis.findPermissionRead('product', path)
    },
    isEditData() {
      let path = '/product'
      return permis.findPermissionEdit('product', path)
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId)
      return shop.uid
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },
    fields() {
      return [{ key: 'name', label: this.$i18n.t('productName') }]
    },
  },
  created() {
    this.getSerial()
    this.getProduct()
    this.shop = this.shops.find((i) => i.objectId === this.shop)
  },
  methods: {
    getSerial() {
      const uid = `${localStorage.getItem('shopsUid')}`
      let params = {
        skuObjectId: this.$route.params.productSKUObjectId,
      }
      const headers = {
        shopObjectId: this.shopObjectId,
      }
      pos({
        url: '/api/v1.0/' + uid + '/productsku/getserial',
        params: params,
        headers: headers,
        method: 'GET',
      }).then((res) => {
        this.SerialList = res.data.data
        this.countSeriallist = this.SerialList.length
      })
    },
    generate() {
      this.SerialNumber = util.generateSerial()
    },
    confirmAdd() {
      if (this.SerialNumber == '') {
        this.isError = 5
        this.titleError = this.$i18n.t('enterSerial')
      } else {
        this.title = this.$i18n.t('confirmSaveSerial')
        this.confirmModal = true
      }
    },
    confirmRemove(item) {
      this.itemDelete = item
      this.deleteModal = true
    },
    removeSerial() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const plan = this.users.currentPlan
      const shopObjectId = this.shopObjectId
      let data = {
        shopObjectId: shopObjectId,
        objectId: this.itemDelete.objectId,
      }
      const headers = {
        shopObjectId: this.shopObjectId,
      }
      this.loading = false
      pos({
        method: 'post',
        url: '/api/v1.0/' + uid + '/ProductSerial/softdelete',
        params: { plan: plan },
        data: data,
        headers: headers,
      })
        .then(
          setTimeout(() => {
            this.loading = true
            this.deleteModal = false
            this.getSerial()
          }, 1000)
        )
        .catch((error) => {
          console.log(error)
        })
    },
    addSerial() {
      const uid = `${localStorage.getItem('shopsUid')}`
      let dataitem = []
      let itemObject = this.$route.params.productSKUObjectId
      const headers = {
        shopObjectId: this.shopObjectId,
      }
      this.loading = false
      dataitem.push(this.SerialNumber)
      pos({
        method: 'post',
        url: '/api/v1.0/' + uid + '/productsku/addserial',
        data: {
          objectId: itemObject,
          SN: dataitem,
        },
        headers: headers,
      })
        .then(() => {
          setTimeout(() => {
            this.loading = true
            this.confirmModal = false
            this.getSerial()
          }, 1000)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getProduct() {
      this.loadingButton = false
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId
      let params = {
        shopObjectId: shopObjectId,
        categoryObjectId: '',
      }
      const headers = {
        shopObjectId: this.shopObjectId,
      }
      let data = {}
      pos({
        url:
          '/api/v1.0/' +
          uid +
          '/ProductSKU/data/' +
          this.$route.params.productSKUObjectId,
        params: params,
        headers: headers,
        method: 'GET',
      }).then((res) => {
        this.data = res.data.data.document
        data = this.data
        this.SKUName = data.SKUName
        this.loadingButton = true
      })
    },
    addProductBOM() {
      this.$router.push('add/' + this.$route.params.productSKUObjectId)
    },
    goback() {
      let productSKUObjectId = this.$route.params.productSKUObjectId
      this.$router.push({
        name: 'ProductDetail',
        params: { productSKUObjectId },
        query: {
          sort: this.$route.query.sort,
          page: this.$route.query.page,
          view: this.$route.view,
        },
      })
    },
  },
}
</script>

<style>
.cardadd {
  min-height: 438px;
  max-height: 400px;
  border-color: #3abc98;
}
.cursor {
  cursor: pointer;
}
</style>
